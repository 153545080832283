<template>
    <div class="flex-box">
      <div class="amount">{{ $gbUtils.formatBalance(orderInfo.remain || 0) }} MAI</div>
      <VanButton class="pop-btn" @click="handleTrade">{{ type === 2 ? $t('otc.purchase') : $t('otc.sell')}}</VanButton>
    </div>
</template>

<script>
import contractOtc from '../../../contracts/contractOtcV1'

export default {
  name: '',
  props: ['id', 'price', 'type', 'index'],
  data () {
    return {
      orderInfo: {}
    }
  },
  methods: {
    async getOrderInfo () {
      const resp = await contractOtc.getOrderInfo(this.id)
      if (!resp.success) return
      this.orderInfo = {
        direct: resp.result.direct,
        price: this.$web3.fromWei(resp.result.price),
        total: this.$web3.fromWei(resp.result.total),
        remain: this.$web3.fromWei(resp.result.remain),
        token: resp.result.token,
        sender: resp.result.sender,
        status: resp.result.status
      }
      if (this.orderInfo.remain <= 0) {
        this.$emit('handleHideItem', this.index)
      }
    },
    handleTrade () {
      this.$emit('handleTrade', this.type, this.id, this.orderInfo)
    }
  },
  mounted () {
    this.getOrderInfo()
  }
}
</script>

<style scoped lang="scss">
.flex-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .amount {
  }
  .pop-btn {
    width: auto;
    height: 24px;
    margin-top: 0;
    background: #6667FF;
  }
}
</style>
