<template>
  <div class="order-box">
    <div class="order-tilte">
      <div class="left">{{ type === 1 ? $t('otc.buyOrder') : $t('otc.soldOrder') }}</div>
      <van-button disabled class="btn"  @click="handleShowHang">{{ type === 1 ? $t('otc.hangBuy') : $t('otc.hangSale') }}</van-button>
    </div>
    <div class="order-list">
      <div class="list-tab">
        <div class="tab">{{ $t('otc.price') }}(MU)</div>
        <div class="tab">{{ $t('otc.total') }}(MAI)</div>
      </div>
      <div class="list-box">
        <OrderPiceTotal ref="OrderPiceTotal" v-for="(item, index) in priceList" :key="index" :price="item" :type="type"/>
        <van-empty v-if="!priceList.length" />
      </div>
    </div>
    <!-- 挂买 -->
    <HangBuy v-if="type === 1 && hangPopShow"
    :lastPrice="lastPrice"
    @close="handleShowHang"/>
    <!-- 挂卖 -->
    <HangSale v-if="type === 2 && hangPopShow" @close="handleShowHang"
    :lastPrice="lastPrice"/>
  </div>
</template>

<script>
import HangBuy from './HangBuy.vue'
import HangSale from './HangSale.vue'
import OrderPiceTotal from './OrderPiceTotal.vue'
import contractOtc from '../../../contracts/contractOtcV1'

export default {
  name: '',
  components: { HangBuy, HangSale, OrderPiceTotal },
  props: ['type', 'lastPrice'],
  data () {
    return {
      hangPopShow: false,
      priceList: [],
      offset: 0,
      size: 10,
      num: 1,
      timer: null
    }
  },
  methods: {
    handleShowHang () {
      this.hangPopShow = !this.hangPopShow
    },
    async getList () {
      const resp = await contractOtc.getMarketPrices(this.type, this.offset, this.size)
      if (!resp.success) return
      var tempList = (resp.result || []).map(item => {
        return this.$web3.fromWei(item)
      })
      // type 1 降序 2 升序
      if (this.type === 1) {
        tempList.sort((a, b) => {
          return b - a
        })
      } else {
        tempList.sort((a, b) => {
          return a - b
        })
      }
      this.priceList = tempList
    },
    init () {
      this.getList()
    },
    initTimer () {
      this.timer = setInterval(() => {
        this.init()
      }, 15000)
    }
  },
  mounted () {
    this.init()
    this.initTimer()
  },
  beforeDestroy () {
    clearInterval(this.timer)
    this.timer = null
  }
}
</script>

<style scoped lang="scss">
.order-box {
  background: #252525;
  border-radius: 25px;
  margin-top: 20px;
  padding: 20px;
  box-sizing: border-box;
  .order-tilte {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    .btn {
      background: #6667FF;
      height: 28px;
      border-radius: 30px;
      color: #fff;
      font-size: 14px;
    }
  }
  .order-list {
    background: #18191A;
    margin-top: 20px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 20px;
  }
  .list-tab {
    display: flex;
    font-size: 12px;
    color: rgba($color: #fff, $alpha: 0.6);
    .tab {
      width: 33%;
      text-align: center;
    }
  }
  .list-box {
    max-height: 280px;
    overflow-y: auto;
  }
}
</style>
