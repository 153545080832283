<template>
  <VanPopup v-model="show" style="background: transparent; width:80%; max-width: 375px; min-width: 300px;" class="otc-pop"
    @click-overlay="close">
    <div class="pop-body">
      <div class="pop-title">{{ type === 2 ? $t('otc.purchase') : $t('otc.sell') }}</div>
      <van-field disabled="" class="pop-input" input-align="right" type="number" v-model="orderInfo.price"
        :label="$t('otc.price')">
        <template #button>MUSD</template>
      </van-field>
      <van-field disabled="" class="pop-input" input-align="right" type="number" v-model="orderInfo.remain"
        :label="$t('otc.total')">
        <template #button>MAI</template>
      </van-field>
      <van-field class="pop-input" input-align="right" type="number" v-model="amount"
        :label="type === 2 ? $t('otc.buyNum') : $t('otc.sellNum')" :placeholder="$t('otc.pleaseInput')">
        <template #button>
          <van-button class="max-btn" size="small" color="#5E12E7" @click="handleMax">Max</van-button>
        </template>
      </van-field>
      <!-- 余额 -->
      <div class="pop-balance">{{ type === 1 ? 'MAI' : 'MUSD'}}: {{ $gbUtils.formatBalance(balance) }}</div>
      <!-- 提示 -->
      <div class="pop-tips"> {{ $t('otc.tradeTips', { num1: canTradeNum, num2: orderInfo.remain}) }}({{ $t('otc.remainingTip', {num: minAmount})}})</div>
      <div class="pop-tips">
        <span v-if="type === 1">{{ $t('otc.expectedToObtain', {num: needAll}) }}</span>
        <span v-if="type === 2"> {{ $t('otc.estimatedConsumption', {num: needAll}) }}</span>
       </div>

      <!-- 按鈕 -->
      <van-button class="pop-btn" v-if="!auth" :loading="approveLoading" @click="approve">{{ $t('otc.approve')
      }}</van-button>
       <van-button class="pop-btn"
       v-else
      :disabled="amount <= 0 || (amount > canTradeNum && amount != +orderInfo.remain) || amount > +orderInfo.remain "
      :loading="tradeLoading"
      @click="handleTrade"
      >{{type === 1 ? $t('otc.sell') : $t('otc.purchase')}}</van-button>
    </div>
    <div class="close-icon" @click="close">
      <img src="@/assets/close.png" />
    </div>
  </VanPopup>
</template>

<script>
import contractOtc from '../../../contracts/contractOtc'
import contractToken from '../../../contracts/contractToken'
import config from '@/config/app.config.js' // 引入配置文件

export default {
  name: '',
  props: ['id', 'type', 'orderInfo'],
  data () {
    return {
      show: true,
      amount: null,
      tokenAddress: '',
      hangLoading: false,
      balance: 0,
      minAmount: 0,
      auth: false,
      approveLoading: true,
      tradeLoading: false
    }
  },
  computed: {
    needAll () {
      return (this.orderInfo.price * this.amount) || 0
    },
    // 可以买的数量范围
    canTradeNum () {
      return +this.orderInfo.remain - this.minAmount
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    handleMax () {
      // 买入 type 2 消耗musd
      if (this.type === 2) {
        const maxNeed = this.orderInfo.price * this.orderInfo.remain
        const canBuyMai = this.balance / this.orderInfo.price
        this.amount = this.balance >= maxNeed ? this.orderInfo.remain
          : (canBuyMai >= this.canTradeNum ? this.canTradeNum : canBuyMai)
      } else {
        // 卖出 type 1 消耗mai
        this.amount = (this.balance >= this.orderInfo.remain) ? this.orderInfo.remain
          : (this.balance >= this.canTradeNum ? this.canTradeNum : this.balance)
      }
    },
    async init () {
      this.getMinAmount()
      await this.gettokenAddress()
      this.getBalance()
      this.getAllowance()
    },
    async getMinAmount () {
      const resp = await contractOtc.MIN_REMAIN_AMOUNT()
      if (!resp.success) return
      this.minAmount = resp.result || 0
    },
    async gettokenAddress () {
      // type 1买单  卖  2 卖单 买
      const resp = this.type === 2 ? await contractOtc.MUSD() : await contractOtc.MAI()
      if (!resp.success) return
      this.tokenAddress = resp.result
    },
    async getBalance () {
      if (!this.tokenAddress) return
      const resp = await this.$web3.balanceOf(this.tokenAddress)
      if (!resp.success) return
      this.balance = resp.result || 0
    },
    // 获取授权额度
    async getAllowance () {
      // 获取授权额度
      this.approveLoading = true
      const resp = await contractToken.allowance(
        this.tokenAddress,
        config.otc // 合约地址
      )
      this.approveLoading = false
      if (!resp.success) return
      this.auth = resp.result > 0
    },
    // 授权
    async approve () {
      this.approveLoading = true
      const resp = await contractToken.approve(
        this.tokenAddress,
        config.otc // 合约地址
      )
      this.approveLoading = false
      if (!resp.success) return this.$toast('fail')
      this.$toast('Success')
      this.auth = true
    },
    async handleTrade () {
      this.tradeLoading = true
      const resp = await contractOtc.trade(this.id, this.amount)
      this.tradeLoading = false
      if (!resp.success) return
      this.$emit('close')
      this.$parent.init()
      this.$parent.$parent.init()
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style scoped lang="scss"></style>
