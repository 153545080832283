import config from '@/config/app.config.js' // 引入配置文件
import contractAbi from '../abi/OTCV1.json' // 分发合约
import web3Obj from '../utils/web3'
import store from '@/store'

const contractAddress = config.otcV1
const contractInstance = new web3Obj.web3.eth.Contract(contractAbi, contractAddress)

/**
 * 獲取mai地址
 */
const MAI = async () => {
  try {
    const resp = await contractInstance.methods
      .MAI()
      .call({})
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * 獲取musd地址
 */
const MUSD = async () => {
  try {
    const resp = await contractInstance.methods
      .MUSD()
      .call({})
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}
/**
 * 獲取最小掛單值
 * @returns
 */
const MIN_AMOUNT = async () => {
  try {
    const resp = await contractInstance.methods
      .MIN_AMOUNT()
      .call({})
    return web3Obj.successResult(web3Obj.fromWei(resp))
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * 獲取最小吃單剩餘值
 * MIN_REMAIN_AMOUNT
 */
const MIN_REMAIN_AMOUNT = async () => {
  try {
    const resp = await contractInstance.methods
      .MIN_REMAIN_AMOUNT()
      .call({})
    return web3Obj.successResult(web3Obj.fromWei(resp))
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * 獲取價格
 * @return amount
 */
const lastPrice = async () => {
  try {
    var balance = await contractInstance.methods
      .lastPrice()
      .call({})
    balance = web3Obj.fromWei(balance)
    return web3Obj.successResult(balance)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * 获取市场价格列表
 * @param {uint256} direct direct-挂单方向：1买; 2-卖
 * @param {uint256} offset 偏移量；
 * @param {uint256} size：每页显示条数
 * @returns []
 */
const getMarketPrices = async (direct, offset, size) => {
  try {
    const resp = await contractInstance.methods
      .getMarketPrices(direct)
      .call({})
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * @param {*} direct 挂单方向：1买; 2-卖
 * @param {*} price 价格
 * @returns 总量
 */
const getPriceTotals = async (direct, price) => {
  try {
    const resp = await contractInstance.methods
      .getPriceTotals(direct, web3Obj.toWei(price))
      .call({})
    return web3Obj.successResult(web3Obj.fromWei(resp))
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

const getMarketIds = async (direct, price) => {
  try {
    const resp = await contractInstance.methods
      .getMarketIds(direct, web3Obj.toWei(price))
      .call({})
    return web3Obj.successResult(resp)
  } catch (error) {
    console.log(error)
    return web3Obj.failResult(error)
  }
}

/**
 * 9、查询挂单详情---getOrderInfo(uint256 _index)
struct Order {
        uint256 direct; // 方向：1买; 2-卖
        uint256 price; // 价格
        uint256 total; // 挂单总数
        uint256 remain; // 剩余数量
        address token; // 支付币种
        address sender; // 挂单人
        uint256 status; // 状态：0-挂单中；1-已完成; 2-已撤单
    }
 */
const getOrderInfo = async (id) => {
  try {
    const resp = await contractInstance.methods
      .getOrderInfo(id)
      .call({})
    return web3Obj.successResult(resp)
  } catch (error) {
    console.log(error)
    return web3Obj.failResult(error)
  }
}
/**
 * 2、挂单--------pending(uint256 direct, uint256 price, uint256 amount)
  参数说明：direct-挂单方向：1买; 2-卖； price-挂单价格； amount-挂单数量
 */
const pending = async (direct, price, amount) => {
  try {
    const resp = await contractInstance.methods
      .pending(direct, web3Obj.toWei(price), web3Obj.toWei(amount))
      .send({ from: store.state.myAcount, ...web3Obj.gasPriceObj })
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}
/**
 * batchTrade
 * 批量吃單
 */
const batchTrade = async (ids) => {
  try {
    const resp = await contractInstance.methods
      .batchTrade(ids)
      .send({ from: store.state.myAcount, ...web3Obj.gasPriceObj })
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * trade
 * 吃單
 */
const trade = async (id, amount) => {
  try {
    const resp = await contractInstance.methods
      .trade(id, web3Obj.toWei(amount))
      .send({ from: store.state.myAcount, ...web3Obj.gasPriceObj })
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * 8、查看用户挂单下标列表---getPendingIds(address _addr, uint256 offset, uint256 size)
 */
const getPendingIds = async (account) => {
  try {
    const resp = await contractInstance.methods
      .getPendingIds(account || store.state.myAcount)
      .call({ })
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

/**
 * 撤單
 * 4、取消--------cancel(uint256 _index)
 * 参数说明：index-下标ID
 */
const cancel = async (id) => {
  try {
    const resp = await contractInstance.methods
      .cancel(id)
      .send({ from: store.state.myAcount, ...web3Obj.gasPriceObj })
    return web3Obj.successResult(resp)
  } catch (error) {
    return web3Obj.failResult(error)
  }
}

export default {
  MAI,
  MUSD,
  MIN_AMOUNT,
  MIN_REMAIN_AMOUNT,
  lastPrice,
  getMarketPrices,
  getPriceTotals,
  getMarketIds,
  getOrderInfo,
  pending,
  batchTrade,
  trade,
  getPendingIds,
  cancel
}
